import React from 'react'
import '../assets/css/mentionspol.css'

function Mentionslegales() {
  const closeMe = () => {
    document.getElementById('mentionsleg').style.visibility = 'hidden'
  }
  return (
    <div
      className='mentions-bg'
      id='mentionsleg'
      onClick={(e) => {
        e.stopPropagation()
        closeMe()
      }}
    >
      <div
        className='mentions-cont'
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <button onClick={() => closeMe()} className='close'></button>
        <h3 className='mentions'>Mentions légales</h3>
        <p>
          <strong>Propriétaire et éditeur du site</strong>
          <br />
          Maître Marie LALANDES
          <br />
          Adresse : 28 Ordokiko Bidea – 64250 ITXASSOU
          <br />
          SIRET : 884 542 952 00020
          <br />
          Tel : +33(0)6 80 70 34 21
          <br />
          Marie.lalandes@avocat.fr
        </p>
        <p>
          <strong>Directeur de la Publication</strong> : Maître Marie LALANDES
        </p>
        <p>
          Maître Marie LALANDES est inscrite au Barreau de BAYONNE, et est ainsi
          rattachée à l’Ordre du barreau de BAYONNE (France). Elle est soumise
          au règlement intérieur national dans sa version applicable ainsi
          qu’aux dispositions législatives et règlementaires relatives à la
          profession d’avocat.
        </p>
        <p>
          <strong>Hébergement</strong>
          <br />
          Ce site est hébergé par la société OVH, SAS au capital de 10 069 020 €
          <br /> RCS Lille Métropole 424 761 419 00045 <br />
          N° TVA : FR 22 424 761 419 <br />
          Siège social : 2 rue Kellermann – 59100 Roubaix – France <br />
          N° de téléphone : +33 9 72 10 10 07
        </p>
        <p></p>
        <p></p>
      </div>
    </div>
  )
}

export default Mentionslegales
