import React from 'react'

function Titre() {
  return (
    <>
      <h1>Marie Lalandes Avocat</h1>
      <h2>Droit des Sociétés - ITXASSOU</h2>
    </>
  )
}

export default Titre
