import React from 'react'
import '../assets/css/contact.css'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'

import adresse from '../assets/img/adresse.svg'
import mail from '../assets/img/mail.svg'
import telephone from '../assets/img/telephone.svg'
import plan from '../assets/img/plan.jpg'

class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      message: '',
      phone: '',
      email: '',
      name: '',
      errora: ['Tous les champs sont obligatoires.'],
    }
  }

  handleSubmit(e) {
    e.preventDefault()

    axios({
      method: 'POST',
      url: 'https://www.lalandesavocat.fr/send/index.php',
      data: this.state,
    }).then((response) => {
      const erMessage = document.getElementById('message-er')
      const erName = document.getElementById('name-er')
      const erPhone = document.getElementById('phone-er')
      const erEmail = document.getElementById('email-er')
      if (response.data.status === 'success') {
        alert('Message envoyé.')
        this.resetForm()
        erMessage.style.display = 'none'
        erName.style.display = 'none'
        erPhone.style.display = 'none'
        erEmail.style.display = 'none'
      } else if (response.data.status === 'fail') {
        this.setState({ errora: response.data.error })
        this.state.errora.message
          ? (erMessage.style.display = 'block')
          : (erMessage.style.display = 'none')
        this.state.errora.name
          ? (erName.style.display = 'block')
          : (erName.style.display = 'none')
        this.state.errora.phone
          ? (erPhone.style.display = 'block')
          : (erPhone.style.display = 'none')
        this.state.errora.email
          ? (erEmail.style.display = 'block')
          : (erEmail.style.display = 'none')
      }
    })
  }
  onNameChange(event) {
    this.setState({ name: event.target.value })
  }
  onPhoneChange(event) {
    this.setState({ phone: event.target.value })
  }

  onEmailChange(event) {
    this.setState({ email: event.target.value })
  }

  onMessageChange(event) {
    this.setState({ message: event.target.value })
  }
  resetForm() {
    this.setState({
      message: '',
      phone: '',
      email: '',
      name: '',
      errora: ['Tous les champs sont obligatoires.'],
    })
  }

  render() {
    return (
      <>
        <Helmet>
          <title>
            Me contacter | Marie Lalandes | Avocat - Droit des Sociétés -
            Itxassou
          </title>
        </Helmet>

        <div className='page-title'>
          <h3>Contact</h3>
        </div>
        <div className='page-content'>
          <div className='contact-flex'>
            <div className='contact-flexbox'>
              <h4>Infos pratiques</h4>
              <p>
                <object
                  type='image/svg+xml'
                  data={adresse}
                  className='infos-svg'
                >
                  Adresse :
                </object>
                28 Ordokiko Bidea
                <br />
                <span style={{ marginLeft: '32px' }}>64250 ITXASSOU</span>
              </p>
              <p>
                <object
                  type='image/svg+xml'
                  data={telephone}
                  className='infos-svg'
                >
                  Numéro de téléphone :
                </object>
                06 80 70 34 21
              </p>
              <p>
                <object type='image/svg+xml' data={mail} className='infos-svg'>
                  Email :
                </object>
                marie.lalandes@avocat.fr
              </p>

              <img src={plan} alt='Plan' className='plan' />
            </div>

            <div className='contact-flexbox'>
              <h4>Formulaire de contact</h4>
              <form
                id='contact-form'
                onSubmit={this.handleSubmit.bind(this)}
                method='POST'
                autoComplete='off'
              >
                <div className='contact-field'>
                  <input
                    className='contact-input'
                    value={this.state.message}
                    placeholder='Nom et prénom'
                    id='message'
                    onChange={this.onMessageChange.bind(this)}
                    required
                  />
                  <span className='contact-form-error' id='message-er'>
                    {this.state.errora.message}
                  </span>
                </div>
                <div className='contact-field'>
                  <input
                    type='text'
                    className='contact-input'
                    id='phone'
                    placeholder='Numéro de téléphone'
                    value={this.state.phone}
                    onChange={this.onPhoneChange.bind(this)}
                    required
                  />

                  <span className='contact-form-error' id='phone-er'>
                    {this.state.errora.phone}
                  </span>
                </div>

                <div className='contact-field'>
                  <input
                    type='email'
                    className='contact-input'
                    id='email'
                    aria-describedby='emailHelp'
                    value={this.state.email}
                    onChange={this.onEmailChange.bind(this)}
                    placeholder='Adresse email'
                    required
                  />
                  <span className='contact-form-error' id='email-er'>
                    {this.state.errora.email}
                  </span>
                </div>
                <div className='contact-field'>
                  <textarea
                    className='contact-input'
                    rows='5'
                    id='name'
                    value={this.state.name}
                    onChange={this.onNameChange.bind(this)}
                    placeholder='Votre message'
                    required
                  />

                  <span className='contact-form-error' id='name-er'>
                    {this.state.errora.name}
                  </span>
                </div>
                <div className='contact-field'>
                  <input type='checkbox' id='rgpd' required />
                  Je consens à ce que ce site stocke mes informations envoyées
                  afin qu’ils puissent répondre à ma requête.
                </div>
                <button type='submit' className='form-btn'>
                  Envoyer
                </button>
                <br />
              </form>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Contact
