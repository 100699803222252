import React from 'react'
import { Helmet } from 'react-helmet-async'

function presentation() {
  return (
    <>
      <Helmet>
        <title>
          Présentation | Marie Lalandes | Avocat - Droit des Sociétés - Itxassou
        </title>
      </Helmet>

      <div className='page-title'>
        <h3>Présentation</h3>
      </div>
      <div className='page-content'>
        <p>
          Maître Marie LALANDES a prêté serment d’Avocat le 18 décembre 2013.
        </p>
        <p>
          Titulaire d’un Master 2 en Droit des Affaires européennes et
          internationales, sa formation a été complétée par un Master Grandes
          Ecoles en Finance d’Entreprises suivi à KEDGE Business School
          BORDEAUX.
        </p>
        <p>
          Elle a ensuite intégré durant cinq ans le département Droit des
          Sociétés du Cabinet Ernst & Young au sein des bureaux de BORDEAUX et
          de BAYONNE.
        </p>
        <p>
          Aujourd’hui et en parallèle de l’exercice de la Profession d’Avocat à
          titre indépendant, Maître Marie LALANDES est Associée au sein de la
          Société SINERGIA, Société pluriprofessionnelle d’Avocats et
          d’Experts-Comptables.
        </p>
      </div>
    </>
  )
}

export default presentation
