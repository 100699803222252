import React from 'react'
import '../assets/css/navigation.css'
import DelayLink from './DelayLink'

function Navigation({ currentPage, setCurrentPage }) {
  const showMenu = (nextP) => {
    const menuHamb = document.getElementsByClassName('hamburger')
    const menuNav = document.getElementById('nav')

    if (currentPage !== nextP) {
      menuHamb[0].classList.remove('is-active')
      menuNav.style.transitionDelay = '0.5s'
      menuNav.style.left = '100%'
    }
  }
  const openMe = (pop) => {
    document.getElementById(pop).style.visibility = 'visible'
  }

  return (
    <div id='nav'>
      <ul className='nav-list'>
        <li
          onClick={() => {
            showMenu('home')
            setCurrentPage('home')
          }}
        >
          <DelayLink delay={2000} to='/' replace={false}>
            <button className='nav-item' id='homelink'>
              Accueil
            </button>
          </DelayLink>
        </li>
        <li
          onClick={() => {
            showMenu('presentation')
            setCurrentPage('presentation')
          }}
        >
          <DelayLink delay={2000} to='/presentation' replace={false}>
            <button className='nav-item' id='presentationlink'>
              Présentation
            </button>
          </DelayLink>
        </li>
        <li
          onClick={() => {
            showMenu('competences')
            setCurrentPage('competences')
          }}
        >
          <DelayLink delay={2000} to='/competences' replace={false}>
            <button className='nav-item' id='competenceslink'>
              Nos compétences
            </button>
          </DelayLink>
        </li>
        <li
          onClick={() => {
            showMenu('contact')
            setCurrentPage('contact')
          }}
        >
          <DelayLink delay={2000} to='/contact' replace={false}>
            <button className='nav-item' id='contactlink'>
              Contact
            </button>
          </DelayLink>
        </li>
      </ul>
      <div className='nav-foot'>
        <button onClick={() => openMe('mentionsleg')} className='nav-mentions'>
          Mentions légales
        </button>
        -
        <button onClick={() => openMe('polconf')} className='nav-mentions'>
          Politique de confidentialité
        </button>
      </div>
    </div>
  )
}

export default Navigation
