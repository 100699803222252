import '../assets/css/home.css'
import { Helmet } from 'react-helmet-async'
import DelayLink from './DelayLink'
function home({ setCurrentPage }) {
  return (
    <>
      <Helmet>
        <title>Marie Lalandes | Avocat - Droit des Sociétés - Itxassou</title>
      </Helmet>
      <div className='page-title'>
        <h3>Accueil</h3>
      </div>
      <div className='page-content'>
        <p>
          Nous sommes spécialisés dans le Conseil aux Entreprises en droit des
          Sociétés et en droit des Affaires.
        </p>

        <DelayLink to='/competences' delay={2000} replace={false}>
          <button
            className='button-text'
            onClick={() => {
              setCurrentPage('competences')
            }}
          >
            Nous intervenons à toutes les étapes de l’évolution d’une
            Entreprise.
          </button>
        </DelayLink>

        <p>
          Nos objectifs sont de vous sécuriser, de faciliter et de simplifier
          vos démarches.
        </p>
        <p>
          En parallèle, nous sommes Associés d’une Société pluriprofessionnelle
          d’Avocats et d’Experts-Comptables : la Société SINERGIA.
        </p>
        <p>
          {' '}
          Au sein de la Société SINERGIA, nous attachons une grande importance à
          nous concentrer individuellement sur nos domaines de compétences
          respectifs.
        </p>
        <p>
          Cette approche nous permet de vous offrir un accompagnement sécurisé
          et entièrement personnalisé.
        </p>
        <p>
          Si vos besoins dépassent nos domaines d’expertise, nous vous mettons
          en relation avec un de nos Partenaires de confiance (Avocat
          fiscaliste, Avocat en Droit Public, Avocat en Propriété
          Intellectuelle, Avocat en Droit Civil).
        </p>
        <p>
          Votre satisfaction et la résolution de vos problématiques sont notre
          priorité.
        </p>
        <p> Nous sommes à votre écoute.</p>
      </div>
    </>
  )
}
export default home
