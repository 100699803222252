import React, { useState, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import App from '../App'
import Home from './home'
import Presentation from './presentation'
import Competences from './competences'
import Contact from './contact'

/* const App = React.lazy(() => import('../App'))
const Home = React.lazy(() => import('./home'))
const Presentation = React.lazy(() => import('./presentation'))
const Competences = React.lazy(() => import('./competences'))
const Contact = React.lazy(() => import('./contact')) */
function AppRoutes() {
  const [currentPage, setCurrentPage] = useState('')

  useEffect(() => {
    const navLinks = document.getElementsByClassName('nav-item')
    Object.keys(navLinks).forEach((element) => {
      if (
        currentPage + 'link' ===
        document.getElementsByClassName('nav-item')[element].id
      ) {
        document.getElementsByClassName('nav-item')[element].style.color =
          'grey'
        document.getElementsByClassName('nav-item')[element].style.cursor =
          'default'
      } else {
        document.getElementsByClassName('nav-item')[element].style.color =
          'white'
        document.getElementsByClassName('nav-item')[element].style.cursor =
          'pointer'
      }

      if (window.innerWidth < 601) {
        document.getElementById('page').style.left = '-100%'
      } else {
        document.getElementById('page').style.left = '-85%'
      }
      if (currentPage !== '') {
        setTimeout(function () {
          console.log(
            JSON.stringify(
              document.getElementById('page').style.transitionDelay
            )
          )
          document.getElementById('page').style.left = '0'
        }, 2000)
      } else {
        document.getElementById('page').style.left = '0'
      }
    })
  }, [currentPage])
  return (
    <Routes>
      <Route path='/' element={<App />}>
        <Route path='' element={<Home setCurrentPage={setCurrentPage} />} />
        <Route path='/presentation' element={<Presentation />} />
        <Route path='/competences' element={<Competences />} />
        <Route path='/contact' element={<Contact />} />
      </Route>
    </Routes>
  )
}

export default AppRoutes
