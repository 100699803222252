import React from 'react'
import '../assets/css/mentionspol.css'

function Polconfidentialite() {
  const closeMe = () => {
    document.getElementById('polconf').style.visibility = 'hidden'
  }
  return (
    <div
      className='mentions-bg'
      id='polconf'
      onClick={(e) => {
        e.stopPropagation()
        closeMe()
      }}
    >
      <div
        className='mentions-cont'
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <h4 className='mentions'>Politique de confidentialité</h4>
        <button onClick={() => closeMe()} className='close'></button>
        <p>
          Maître Marie LALANDES est responsable du traitement des données à
          caractère personnel (ci-après "DCP") des utilisateurs du site
          Internet.
        </p>
        <p>
          Elle prend les dispositions nécessaires afin d'assurer un niveau de
          sécurité des données à caractère personnel, en conformité avec la loi
          française n°78-17, dite « Informatique et libertés », et le Règlement
          européen sur la Protection des Données n°2016/79 du 27 avril 2016.
        </p>
        <p>
          Le Cabinet collecte les informations que les Utilisateurs du site
          internet https://www.lalandesavocat.fr/ (ci-après « Utilisateur(s) »)
          fournissent volontairement via les formulaires de contact.
        </p>
        <p>
          Lorsque l’Utilisateur remplit des champs dans un formulaire en ligne,
          le caractère obligatoire de l’information qui lui est demandée est
          signalé, car ces informations sont nécessaires afin de permettre à
          Maître Marie LALANDES de recontacter l’Utilisateur qui en fait la
          demande.
        </p>
        <p>
          Les données à caractère personnel sont conservées pendant une durée
          qui n’excède pas la durée nécessaire aux finalités pour lesquelles
          elles ont été collectées.
        </p>
        <p>
          Les données à caractère personnel des Utilisateurs du Site internet ne
          sont pas communiquées à des tiers.
        </p>
        <p>
          L’Utilisateur dispose d’un droit d’accès, de rectification et
          d’effacement de ses données caractère personnel le concernant, ainsi
          que d’un droit à la limitation du traitement et à la portabilité de
          ses données.
        </p>
        <p>
          Il dispose également d’un droit d’opposition pour motif légitime au
          traitement de ses données à caractère personnel, ainsi que d’un droit
          d’opposition au traitement de ses données à des fins de prospection.
          Il dispose enfin d’un droit d’introduire une réclamation auprès de la
          CNIL.
        </p>
        <p>
          L’utilisateur des services proposés par Maître Marie LALANDES peut
          exercer ses droits à tout moment en adressant une demande directement
          au cabinet d'avocats, accompagnée de la photocopie d’un titre
          d’identité portant la signature de la personne concernée et précisant
          l’adresse à laquelle devra parvenir la réponse: <br />
          - soit par courriel à marie.lalandes@avocat.fr <br />- soit par
          courrier postal au 28 Ordokiko Bidea – 64250 ITXASSOU.
        </p>
        <p>
          Une réponse sera alors adressée à la personne concernée dans un délai
          compris entre un et trois mois suivant la réception de ladite demande.
        </p>
        <h4>Propriété intellectuelle</h4>
        <p>
          Le contenu du site, à savoir, la structure générale, les images, les
          sons et les textes dont le site est composé sont la propriété
          exclusive de Maître Marie LALANDES. Toute représentation totale ou
          partielle de ce site et de son contenu, par quelques procédés que ce
          soient, sans l'autorisation préalable expresse de Maître Marie
          LALANDES est interdite et constituerait une contrefaçon sanctionnée
          par les articles L335-2 et suivants du Code de la Propriété
          intellectuelle.
        </p>
        <h4>Responsabilité</h4>
        <p>
          Maître Marie LALANDES n'est responsable que du contenu des pages
          qu'elle édite. Elle ne peut en aucun cas être tenu responsable de tout
          dommage susceptible d'intervenir sur le système informatique de
          l'Utilisateur et/ou de la perte de données résultant de l'utilisation
          du Site par l'Utilisateur.
        </p>
        <p>
          En outre, l'Utilisateur reconnaît que les caractéristiques et les
          contraintes du réseau internet ne permettent pas de garantir la
          disponibilité et le bon fonctionnement du Site. En conséquence, Maître
          Marie LALANDES ne garantit pas que le Site fonctionnera sans
          interruption ni erreur. A ce titre, il est précisé que : <br />- le
          Site est accessible 24 heures sur 24, 7 jours sur 7 sauf cas de force
          majeure ou événement hors du contrôle de Maître Marie LALANDES. <br />
          - Maître Marie LALANDES peut être amenée à interrompre l'accès à tout
          ou partie du Site, à tout ou partie des Utilisateurs, de façon
          temporaire pour des raisons de maintenance visant le bon
          fonctionnement du Site, sans préavis et sans que cette interruption
          puisse donner droit à une quelconque indemnité pour l'Utilisateur.
        </p>
        <p>
          L'Utilisateur déclare être bien informé des caractéristiques
          intrinsèques de l'internet et notamment du fait que la transmission
          des données sur l'internet ne bénéficie que d'une fiabilité relative,
          celles-ci circulant sur des réseaux hétérogènes, aux caractéristiques
          et capacités diverses, qui sont parfois saturés à certaines périodes
          de la journée et de nature à impacter les délais de téléchargement ou
          l'accessibilité aux données, et que l'internet est un réseau ouvert
          et, qu'en conséquence, les informations qu'il véhicule ne sont pas
          protégées contre les risques de détournement, d'intrusion dans son
          système, de piratage des données, programmes et fichiers de son
          système, de contamination par des virus informatiques, et qu'il lui
          appartient de prendre toutes les mesures appropriées de façon à
          protéger les données, fichiers ou programmes stockés dans son système
          contre la contamination par des virus comme des tentatives d'intrusion
          dans son système.
        </p>
      </div>
    </div>
  )
}

export default Polconfidentialite
