import React from 'react'
import '../assets/css/navbutton.css'

function Navbutton() {
  const showMenu = () => {
    const menuHamb = document.getElementsByClassName('hamburger')
    const menuNav = document.getElementById('nav')
    let menuLeft = 0
    if (window.innerWidth > 850) {
      menuLeft = window.innerWidth - 376
      menuNav.style.width = '376px'
    } else {
      menuLeft = '0'
      menuNav.style.width = '100%'
      menuNav.style.borderLeft = '0'
    }

    if (menuNav.style.left === menuLeft + 'px') {
      menuHamb[0].classList.remove('is-active')
      menuNav.style.transitionDelay = '0s'
      menuNav.style.left = '100%'
    } else {
      menuNav.style.transitionDelay = '0s'
      menuNav.style.left = menuLeft + 'px'
      menuHamb[0].classList.add('is-active')
    }
  }

  return (
    <button
      className='hamburger hamburger--spring'
      onClick={showMenu}
      type='button'
    >
      <span className='hamburger-box'>
        <span className='hamburger-inner'></span>
      </span>
    </button>
  )
}

export default Navbutton
